<template>
  <p>
    <template v-for="(content,index2) in paragraph.content" v-bind:key="index2">
      <template v-if="content.type=='text'">
        <span v-bind:class="{ 'font-weight-bold text-primary' : content.marks && content.marks[0].type }">
          {{content.text}}
        </span>
      </template>
      <template v-if="content.type=='image'">
        <div class="text-center my-5">
          <img style="max-width:90%" v-if="content.attrs" :src="content.attrs.src" :alt="content.attrs.alt" :title="content.attrs.title"/>
        </div>
      </template>
    </template>
  </p>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Paragraph',
  props: [
    'section',
  ],
  data() {
    return {
      paragraph: this.section
    };
  },
  created() {
    
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
