<template>
  <ul>
    <template v-for="(content,index2) in bulletsList.content" v-bind:key="index2">
      <li>
        <template v-if="content.type=='list_item'">
          <template  v-for="(content2,index3) in content.content" v-bind:key="index3">
            <template v-if="content2.type=='paragraph'">
              <Paragraph :section="content2"></Paragraph>
            </template>
          </template>
        </template>
      </li>
    </template>
  </ul>
</template>

<script>

import axios from 'axios';
import Paragraph from './Paragraph.vue'

export default {
  name: 'BulletsList',
  props: [
    'section',
  ],
  data() {
    return {
      bulletsList: this.section
    };
  },
  created() {
    
  },
  components: {
    Paragraph
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
